import { request, externalRequest } from './httpSessionService';

export async function sendOfferToUs(payload) {
  const path = 'send-offer-juststone';
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function calculateDistance(payload) {
  const path = `https://amantnv.be/api/api.php?do=calculateDistance&query=${payload}`;
  const [resp, status] = await externalRequest(path, 'GET');
  return [resp, status];
}