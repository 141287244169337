import React from "react";
import styled, { keyframes } from "styled-components";

import KitchenWorkSheetForm from "./KitchenWorkSheetForm";
const KitchenWorkSheetForms = ({ kitchenType, sizesState, className, sinkTypes, materialMaxSizes }) => {
  return (
    <Container className={className}>
      <Title>Stap 3: Vul de afmetingen van je werkbladen in</Title>
      <WorkSheetContainer>
        {[...Array(kitchenType?.worksheets)]?.map((item, i) => (
          <KitchenWorkSheetForm
            workSheet={item}
            materialMaxSizes={materialMaxSizes}
            worksheetImage={kitchenType?.worksheetImages?.[i]}
            id={`worksheet${i}`}
            key={i}
            sinkTypes={sinkTypes}
            title={`Werkblad ${i + 1}`}
            sizesState={sizesState}
          />
        ))}
      </WorkSheetContainer>
    </Container>
  );
};

const Title = styled.h2`
  font-size: 2.4rem;
  margin-bottom: 1rem;
  font-weight: bold;
  display: inline-block;
  grid-column: 1/-1;
`;

const WorkSheetContainer = styled.div`
  display: flex;
  margin: -1rem;
  flex-wrap: wrap;
  @media only screen and (max-width: 1400px) {
    & > div {
      width: calc(50% - 2rem);
    }
  }

  @media only screen and (max-width: 900px) {
    & > div {
      width: 100%;
    }
  }
`;

const FadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  animation: ${FadeIn} 300ms ease;
  transition: opacity 0.3s ease;
  margin-bottom: 4rem;
  &.item-enter {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }
  &.item-enter-active {
    opacity: 1;
    max-height: 200rem;
    transition: opacity 200ms 200ms ease-in, visibility 200ms 200ms ease-in,
      max-height 200ms 200ms ease-in;
  }
  &.item-exit {
    opacity: 1;
    visibility: visible;
  }
  &.item-exit-active {
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease-in, visibility 200ms ease-in;
  }
`;

export default KitchenWorkSheetForms;
