import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { CSSTransition, TransitionGroup } from "react-transition-group";

// Components
// import KitchenDrawer from "../components/WebGL";
import TypeSelector from "../components/TypeSelector";
import KitchenWorkSheetForms from "../components/KitchenWorkSheetForms";
import KitchenMaterialSelectorContainer from "../components/KitchenMaterialSelectorContainer";
import KitchenIslandComponent from "../components/KitchenIslandComponent";
import ActionButton from "../components/ActionButton";
import { calculatePrice } from "../services/priceCalculationService";
import Modal from "../components/Modal";
import SendOfferForm from "../components/SendOfferForm";
import LoadingButton from "../components/LoadingButton";
import { sinkTypes } from "../constants/sinkTypes.json";
import { materials } from "../constants/materials.json";
import * as Yup from "yup";
import useForm from "../hooks/useForm";

import { number } from "../services/validationSchemas";
import TextInput from "../components/TextInput";
import { calculateDistance } from "../services/apiRouterService";

const kitchenTypes = {
  SINGLE: {
    key: 0,
    name: "Enkel blad",
    img: "https://juststone.eu/images/kitchens/enkel.svg",
    imgisland: "https://juststone.eu/images/kitchens/enkeleiland.svg",
    worksheets: 1,
    worksheetImages: ["https://juststone.eu/images/kitchens/enkel.svg"],
  },
  L_SHAPE_HORIZONTAL: {
    key: 1,
    name: "L-vorm horizontale voeg",
    img: "https://juststone.eu/images/kitchens/lvormhorizontaal.svg",
    imgisland:
      "https://juststone.eu/images/kitchens/lvormhorizontaaleiland.svg",
    worksheets: 2,
    worksheetImages: [
      "https://juststone.eu/images/kitchens/lvormhorizontaal1.svg",
      "https://juststone.eu/images/kitchens/lvormhorizontaal2.svg",
    ],
  },
  L_SHAPE_VERTICAL: {
    key: 2,
    name: "L-vorm verticale voeg",
    img: "https://juststone.eu/images/kitchens/lvormverticaal.svg",
    imgisland: "https://juststone.eu/images/kitchens/lvormverticaaleiland.svg",
    worksheets: 2,
    worksheetImages: [
      "https://juststone.eu/images/kitchens/lvormverticaal1.svg",
      "https://juststone.eu/images/kitchens/lvormverticaal2.svg",
    ],
  },
  U_SHAPE_HORIZONTAL: {
    key: 3,
    name: "U-vorm horizontale voeg",
    img: "https://juststone.eu/images/kitchens/uvormhorizontaal.svg",
    imgisland:
      "https://juststone.eu/images/kitchens/uvormhorizontaaleiland.svg",
    worksheets: 3,
    worksheetImages: [
      "https://juststone.eu/images/kitchens/uvormhorizontaal1.svg",
      "https://juststone.eu/images/kitchens/uvormhorizontaal2.svg",
      "https://juststone.eu/images/kitchens/uvormhorizontaal3.svg",
    ],
  },
  U_SHAPE_VERTICAL: {
    key: 3,
    name: "U-vorm verticale voeg",
    img: "https://juststone.eu/images/kitchens/uvormverticaal.svg",
    imgisland: "https://juststone.eu/images/kitchens/uvormverticaaleiland.svg",
    worksheets: 3,
    worksheetImages: [
      "https://juststone.eu/images/kitchens/uvormverticaal1.svg",
      "https://juststone.eu/images/kitchens/uvormverticaal2.svg",
      "https://juststone.eu/images/kitchens/uvormverticaal3.svg",
    ],
  },
};

const KitchenPlanner = () => {
  const [kitchenType, setKitchenType] = useState();
  const [hasKitchenIsland, setHasKitchenIsland] = useState(false);
  const [showSendOfferModal, setShowSendOfferModal] = useState(false);
  const [kitchenIsland, setKitchenIsland] = useState();
  const [loadingCity, setLoadingCity] = useState(false);
  const [delivered, setDelivered] = useState(false);
  const [installed, setInstalled] = useState(false);
  const [deliverCity, setDeliverCity] = useState();
  const [travelDistance, setTravelDistance] = useState();
  const [measured, setMeasured] = useState(false);
  const [sizes, setSizes] = useState({});
  const [prices] = useState({});
  const [calculatedPrices, setCalculatedPrices] = useState({});
  const [material, setMaterial] = useState();
  const nodeRef = useRef(null);

  const SignupSchema = Yup.object().shape({
    zipcode: number.required.min(1000, "Te klein").max(9999, "Te groot"),
  });

  const { handleChange, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
  });

  useEffect(() => {
    if (!hasKitchenIsland) setKitchenIsland();
  }, [hasKitchenIsland]);

  useEffect(() => {
    setSizes({});
  }, [kitchenType]);

  useEffect(() => {
    calculatePrice({
      kitchenType,
      sizes,
      material,
      kitchenIsland,
      travelDistance,
      measured,
      installed,
      delivered,
    })
      .then((res) => {
        const [perWorksheet, total] = res || {};
        const {
          totalWorksheetPrices,
          edgesPerWorksheet,
          surfacesPerWorksheet,
          sinksPerWorksheet,
          stovesPerWorksheet,
          elektricityPerWorksheet,
          deliveryPrice,
          installPrice,
          measurePrice,
          totalServicePrice,
        } = perWorksheet || {};

        setCalculatedPrices({
          edgesPerWorksheet,
          totalWorksheetPrices,
          surfacesPerWorksheet,
          sinksPerWorksheet,
          stovesPerWorksheet,
          elektricityPerWorksheet,
          deliveryPrice,
          installPrice,
          measurePrice,
          totalServicePrice,
          total,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, [
    material,
    sizes,
    kitchenType,
    kitchenIsland,
    hasKitchenIsland,
    travelDistance,
    measured,
    installed,
    delivered,
  ]);

  const calculateDeliveryPrices = async (values) => {
    setLoadingCity(true);
    const [resp, status] = await calculateDistance(values?.zipcode);
    if (status === 200) {
      if (resp?.resourceSets?.length > 0) {
        if (resp?.resourceSets?.[0]?.resources?.length > 0) {
          setTravelDistance(
            resp?.resourceSets?.[0]?.resources?.[0].travelDistance
          );
          setDeliverCity(
            resp?.resourceSets?.[0]?.resources?.[0].routeLegs?.[0]?.endLocation
              ?.address?.locality
          );
        }
      }
    }
    setLoadingCity(false);
  };

  const handleSubmitZipcode = (e) => {
    e.preventDefault();
    handleSubmit(calculateDeliveryPrices);
  };

  return (
    <Container>
      <Modal
        showModal={showSendOfferModal}
        setShowModal={setShowSendOfferModal}
        title="Verstuur offerte"
        canClose
      >
        <SendOfferForm
          material={material}
          sizes={sizes}
          img={kitchenTypes[kitchenType]?.img}
          kitchenType={kitchenTypes[kitchenType]?.name}
          state={[showSendOfferModal, setShowSendOfferModal]}
          calculatedPrices={calculatedPrices}
          materials={materials}
          measured={measured}
          delivered={delivered}
          installed={installed}
          kitchenIsland={kitchenIsland}
        />
      </Modal>
      <TypeSelector
        typeState={[kitchenType, setKitchenType]}
        kitchenTypes={kitchenTypes}
      />
      {kitchenType !== undefined && (
        <TransitionGroup className="WorksheetList">
          <CSSTransition
            nodeRef={nodeRef}
            key={kitchenType}
            timeout={300}
            classNames="item"
          >
            <React.Fragment>
              <KitchenMaterialSelectorContainer
                materialState={[material, setMaterial]}
                materials={materials}
              />
              {material !== undefined && (
                <>
                  <KitchenWorkSheetForms
                    kitchenType={kitchenTypes[kitchenType]}
                    sizesState={[sizes, setSizes]}
                    sinkTypes={sinkTypes}
                    prices={prices}
                    materialMaxSizes={
                      materials[material?.categoryKey]?.items?.[
                        material?.materialKey
                      ]?.max_size
                    }
                  />
                  <KitchenIslandComponent
                    kitchenType={kitchenTypes[kitchenType]}
                    kitchenIslandState={[kitchenIsland, setKitchenIsland]}
                    hasKitchenIslandState={[
                      hasKitchenIsland,
                      setHasKitchenIsland,
                    ]}
                    sinkTypes={sinkTypes}
                    materialMaxSizes={
                      materials[material?.categoryKey]?.items?.[
                        material?.materialKey
                      ]?.max_size
                    }
                  />
                  <TotalContainer>
                    <Title>Afronden</Title>
                    <CheckBoxContainer>
                      <CheckBoxItem>
                        <Label>Opmeten</Label>
                        <QuestionContainer>
                          <HasKitchenIslandLabel
                            checked={measured}
                            htmlFor="is_measured"
                          >
                            <Input
                              type="radio"
                              onChange={(e) => setMeasured(true)}
                              checked={measured}
                              value={true}
                              name="is_measured"
                              id="is_measured"
                            />
                            Ja
                          </HasKitchenIslandLabel>
                          <HasKitchenIslandLabel
                            checked={!measured}
                            htmlFor="is_not_measured"
                          >
                            <Input
                              type="radio"
                              onChange={(e) => setMeasured(false)}
                              checked={!measured}
                              value={false}
                              name="is_not_measured"
                              id="is_not_measured"
                            />
                            Nee
                          </HasKitchenIslandLabel>
                        </QuestionContainer>
                      </CheckBoxItem>
                      <CheckBoxItem>
                        <Label>Leveren</Label>
                        <QuestionContainer>
                          <HasKitchenIslandLabel
                            disabled={installed}
                            checked={delivered}
                            htmlFor="is_delivered"
                          >
                            <Input
                              type="radio"
                              disabled={installed}
                              onChange={(e) => setDelivered(true)}
                              checked={delivered}
                              value={true}
                              name="is_delivered"
                              id="is_delivered"
                            />
                            Ja
                          </HasKitchenIslandLabel>
                          <HasKitchenIslandLabel
                            disabled={installed}
                            checked={!delivered}
                            htmlFor="is_not_delivered"
                          >
                            <Input
                              type="radio"
                              disabled={installed}
                              onChange={(e) => setDelivered(false)}
                              checked={!delivered}
                              value={false}
                              name="is_not_delivered"
                              id="is_not_delivered"
                            />
                            Nee
                          </HasKitchenIslandLabel>
                        </QuestionContainer>
                      </CheckBoxItem>
                      <CheckBoxItem>
                        <Label>Laten plaatsen</Label>
                        <QuestionContainer>
                          <HasKitchenIslandLabel
                            checked={installed}
                            htmlFor="is_installed"
                          >
                            <Input
                              type="radio"
                              onChange={(e) => {
                                setInstalled(true);
                                setDelivered(true);
                              }}
                              checked={installed}
                              value={true}
                              name="is_installed"
                              id="is_installed"
                            />
                            Ja
                          </HasKitchenIslandLabel>
                          <HasKitchenIslandLabel
                            checked={!installed}
                            htmlFor="is_not_installed"
                          >
                            <Input
                              type="radio"
                              onChange={(e) => setInstalled(false)}
                              checked={!installed}
                              value={false}
                              name="is_not_installed"
                              id="is_not_installed"
                            />
                            Nee
                          </HasKitchenIslandLabel>
                        </QuestionContainer>
                      </CheckBoxItem>
                    </CheckBoxContainer>
                    {(delivered || measured || installed) && (
                      <PostCodeForm onSubmit={(e) => handleSubmitZipcode(e)}>
                        <Label>
                          Postcode (Nodig om accurate levering en opmeetkosten
                          te berekenen)
                        </Label>
                        <ButtonContainerPostcode>
                          <StyledTextInput
                            onChange={handleChange}
                            value={values?.zipcode}
                            name="zipcode"
                            type="number"
                          />
                          {loadingCity ? (
                            <StyledLoadingButtonPostcode>
                              Opzoeken
                            </StyledLoadingButtonPostcode>
                          ) : (
                            <StyledActionButtonPostcode>
                              Opzoeken
                            </StyledActionButtonPostcode>
                          )}
                        </ButtonContainerPostcode>
                        {deliverCity && (
                          <DeliverLocation>
                            Huidige locatie: {deliverCity}
                          </DeliverLocation>
                        )}
                      </PostCodeForm>
                    )}
                    <Description>
                      Dit is de offerte van jouw ingegeven keukenbladen. Als u
                      wilt, kan u deze offerte doorsturen en wij nemen hierna
                      contact met u op.
                    </Description>
                    <OfferTable>
                      {Object.keys(calculatedPrices?.surfacesPerWorksheet)?.map(
                        (key, i) => (
                          <WorksheetRow key={i}>
                            <WorksheetRowHead>
                              <WorksheetName>
                                {key === "kitchenIsland"
                                  ? "Keukeneiland"
                                  : `Werkblad ${i + 1}`}
                                {" - "}
                                {
                                  materials[material?.categoryKey]?.items?.[
                                    material?.materialKey
                                  ]?.name
                                }
                                {" - "}
                                {key === "kitchenIsland"
                                  ? `${kitchenIsland?.length}mm - ${kitchenIsland?.width}mm`
                                  : `${sizes?.[key]?.length}mm - ${sizes?.[key]?.width}mm`}
                              </WorksheetName>
                              <TotalPrice>
                                {Number(
                                  calculatedPrices?.surfacesPerWorksheet?.[key]
                                ).toLocaleString("nl-BE", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </TotalPrice>
                            </WorksheetRowHead>
                            {calculatedPrices?.edgesPerWorksheet?.[key]
                              ?.finished > 0 && (
                              <ItemRow>
                                <ItemRowName>Afgewerkte zijden{calculatedPrices?.edgesPerWorksheet?.[key]
                              ?.doubled && ` (Verdubbeld)`}:</ItemRowName>
                                <ItemRowPrice>
                                  {Number(
                                    calculatedPrices?.edgesPerWorksheet?.[key]
                                      ?.finished
                                  ).toLocaleString("nl-BE", {
                                    style: "currency",
                                    currency: "EUR",
                                  })}
                                </ItemRowPrice>
                              </ItemRow>
                            )}
                            {calculatedPrices?.sinksPerWorksheet?.[key]?.map(
                              (item, j) => (
                                <ItemRow key={j}>
                                  <ItemRowName>
                                    Spoelbak: {item.name}:
                                  </ItemRowName>
                                  <ItemRowPrice>
                                    {item?.discounted ? (
                                      <>
                                        <OldPrice>
                                          {Number(item?.price).toLocaleString(
                                            "nl-BE",
                                            {
                                              style: "currency",
                                              currency: "EUR",
                                            }
                                          )}
                                        </OldPrice>
                                        <NewPrice>
                                          {Number(
                                            item?.actualPrice
                                          ).toLocaleString("nl-BE", {
                                            style: "currency",
                                            currency: "EUR",
                                          })}
                                        </NewPrice>
                                      </>
                                    ) : (
                                      <NewPrice>
                                        {Number(
                                          item?.actualPrice
                                        ).toLocaleString("nl-BE", {
                                          style: "currency",
                                          currency: "EUR",
                                        })}
                                      </NewPrice>
                                    )}
                                  </ItemRowPrice>
                                </ItemRow>
                              )
                            )}
                            {calculatedPrices?.stovesPerWorksheet?.[key]?.map(
                              (item, j) => (
                                <ItemRow key={j}>
                                  <ItemRowName>Uitsparing opbouw kookplaat:</ItemRowName>
                                  <ItemRowPrice>
                                    {Number(item).toLocaleString("nl-BE", {
                                      style: "currency",
                                      currency: "EUR",
                                    })}
                                  </ItemRowPrice>
                                </ItemRow>
                              )
                            )}
                            {calculatedPrices?.elektricityPerWorksheet?.[
                              key
                            ]?.map((item, j) => (
                              <ItemRow key={j}>
                                <ItemRowName>Uitsparing powerport:</ItemRowName>
                                <ItemRowPrice>
                                  {Number(item).toLocaleString("nl-BE", {
                                    style: "currency",
                                    currency: "EUR",
                                  })}
                                </ItemRowPrice>
                              </ItemRow>
                            ))}
                            <ItemRow>
                              <ItemRowName>Subtotaal werkblad:</ItemRowName>
                              <ItemRowPrice>
                                {Number(
                                  calculatedPrices?.totalWorksheetPrices?.[key]
                                ).toLocaleString("nl-BE", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </ItemRowPrice>
                            </ItemRow>
                          </WorksheetRow>
                        )
                      )}
                      {(measured || delivered || installed) && (
                        <WorksheetRow>
                          <WorksheetRowHead>
                            <WorksheetName>Diensten</WorksheetName>
                            <TotalPrice />
                          </WorksheetRowHead>
                          {measured && (
                            <ItemRow>
                              <ItemRowName>Opmeten:</ItemRowName>
                              <ItemRowPrice>
                                {calculatedPrices?.measurePrice > 0
                                  ? Number(
                                      calculatedPrices?.measurePrice
                                    ).toLocaleString("nl-BE", {
                                      style: "currency",
                                      currency: "EUR",
                                    })
                                  : "Op aanvraag"}
                              </ItemRowPrice>
                            </ItemRow>
                          )}
                          {delivered && !installed && (
                            <ItemRow>
                              <ItemRowName>Leveren:</ItemRowName>
                              <ItemRowPrice>
                                {calculatedPrices?.deliveryPrice > 0
                                  ? Number(
                                      calculatedPrices?.deliveryPrice
                                    ).toLocaleString("nl-BE", {
                                      style: "currency",
                                      currency: "EUR",
                                    })
                                  : "Op aanvraag"}
                              </ItemRowPrice>
                            </ItemRow>
                          )}
                          {installed && delivered && (
                            <>
                              <ItemRow>
                                <ItemRowName>Voorrijkosten:</ItemRowName>
                                <ItemRowPrice>
                                  {calculatedPrices?.deliveryPrice > 0
                                    ? Number(
                                        calculatedPrices.deliveryPrice
                                      ).toLocaleString("nl-BE", {
                                        style: "currency",
                                        currency: "EUR",
                                      })
                                    : "Op aanvraag"}
                                </ItemRowPrice>
                              </ItemRow>
                              <ItemRow>
                                <ItemRowName>Plaatsen:</ItemRowName>
                                <ItemRowPrice>
                                  {calculatedPrices?.installPrice > 0
                                    ? Number(
                                        calculatedPrices?.installPrice
                                      ).toLocaleString("nl-BE", {
                                        style: "currency",
                                        currency: "EUR",
                                      })
                                    : "Op aanvraag"}
                                </ItemRowPrice>
                              </ItemRow>
                            </>
                          )}
                          <ItemRow>
                            <ItemRowName>Subtotaal diensten:</ItemRowName>
                            <ItemRowPrice>
                              {Number(
                                calculatedPrices?.totalServicePrice
                              ).toLocaleString("nl-BE", {
                                style: "currency",
                                currency: "EUR",
                              })}
                            </ItemRowPrice>
                          </ItemRow>
                        </WorksheetRow>
                      )}
                    </OfferTable>
                    <Title>
                      Totaal:{" "}
                      {Number(calculatedPrices.total).toLocaleString("nl-BE", {
                        style: "currency",
                        currency: "EUR",
                      }) || "-"}{" "}
                      EXCL. BTW
                    </Title>
                    <ButtonContainer>
                      <StyledActionButton
                        disabled={
                          !calculatedPrices?.total ||
                          calculatedPrices?.total === 0
                        }
                        onClick={() => setShowSendOfferModal(true)}
                      >
                        Verstuur offerte
                      </StyledActionButton>
                      <StyledActionButton onClick={() => window.print()}>
                        Druk af
                      </StyledActionButton>
                    </ButtonContainer>
                  </TotalContainer>
                </>
              )}
            </React.Fragment>
          </CSSTransition>
        </TransitionGroup>
      )}
      {/* <KitchenDrawer /> */}
    </Container>
  );
};

const CheckBoxContainer = styled.div`
  display: flex;
  margin: -1rem;
  margin-top: 2rem;
  flex-wrap: wrap;
`;

const CheckBoxItem = styled.div`
  margin: 1rem;
`;

const StyledTextInput = styled(TextInput)`
  max-width: 15rem;
  height: auto;
  & > label > input {
    margin-top: 0;
  }
`;

const PostCodeForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const DeliverLocation = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 1rem;
`;

const ButtonContainerPostcode = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: flex-end;
`;

const StyledLoadingButtonPostcode = styled(LoadingButton)`
  margin: 0;
  margin-left: 1rem;
`;

const StyledActionButtonPostcode = styled(ActionButton)`
  margin: 0;
  margin-left: 1rem;
`;

const HasKitchenIslandLabel = styled.label`
  border: 1px solid gray;
  padding: 1rem 2rem;
  margin: 0.5rem;
  cursor: pointer;
  border-radius: 3px;
  border-color: ${({ checked }) => (checked ? "#366399" : "gray")};
  color: ${({ checked }) => (checked ? "white" : "gray")};
  background-color: ${({ checked, disabled }) =>
    checked
      ? disabled
        ? "#969696"
        : "#366399"
      : disabled
      ? "#e4e4e4"
      : "none"};
  transition: all 0.2s ease, transform 0.1s ease;

  &:active {
    transform: scale(0.9);
  }

  &:hover {
    background-color: ${({ checked, disabled }) =>
      disabled ? "auto" : checked ? "#366399" : "#7B9FC9"};
    color: ${({ disabled }) => (disabled ? "auto" : "white")};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }

  & > input {
    display: none;
  }
`;

const QuestionContainer = styled.div`
  margin: 1rem -0.5rem 2rem -0.5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
`;

const Input = styled.input``;

const OldPrice = styled.span`
  text-decoration: line-through;
  color: #cdcdcd;
  margin-right: 1rem;
`;

const NewPrice = styled.span``;

const ItemRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  justify-content: space-between;
`;
const ItemRowName = styled.div``;
const ItemRowPrice = styled.div``;

const WorksheetName = styled.div`
  font-weight: bold;
`;

const TotalPrice = styled.div``;

const WorksheetRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  align-items: flex-end;
`;

const WorksheetRowHead = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  margin: 0 -1rem;
  display: flex;
  margin-top: 2rem;
`;

const StyledActionButton = styled(ActionButton)`
  margin: 0 1rem;
`;

const TotalContainer = styled.div`
  width: 100%;
  background-color: white;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  padding: 2rem;
  border: 1px solid gray;
  padding-bottom: 4rem;
`;

const Title = styled.h2`
  font-weight: bold;
`;

const Label = styled.h2`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  font-size: 1.4rem;
  color: #5b5550;
`;

const Description = styled.p``;

const OfferTable = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;

const Container = styled.div`
  padding: 2rem;
  max-width: 100vw;
  overflow: hidden;
`;

export default KitchenPlanner;
