import { sinkTypes } from "../constants/sinkTypes.json";
import { materials } from "../constants/materials.json";

const deliveryPrices = {
  LESS_THAN_25: 75,
  BETWEEN_25_AND_50: 100,
  BETWEEN_50_AND_75: 125,
  BETWEEN_75_AND_100: 150,
  MORE_THAN_100: null,
};

const deliveryWithInstallPrices = {
  LESS_THAN_25: 25,
  BETWEEN_25_AND_50: 50,
  BETWEEN_50_AND_75: 75,
  BETWEEN_75_AND_100: 100,
  MORE_THAN_100: null,
};

const measurePrices = {
  LESS_THAN_25: 25,
  BETWEEN_25_AND_50: 50,
  BETWEEN_50_AND_75: 75,
  BETWEEN_75_AND_100: 100,
  MORE_THAN_100: null,
};

const installPrices = {
  1: 150,
  2: 275,
  3: 385,
  4: 575,
  island: 275,
};

export const calculateSingleEdgePrices = (worksheets) =>
  new Promise((resolve) => {
    const workSheetEdgePrices = {};
    const totalPrice = {
      finished: 0,
    };
    Object.keys(worksheets).forEach((worksheetKey) => {
      const { finishedSides, length, width, doubled } =
        worksheets[worksheetKey] || {};
      let sidesLength = [];

      // Calculate the prices for polished edges
      switch (worksheetKey) {
        case "worksheet0":
          sidesLength = Object.keys(finishedSides || {})
            .map((key) => {
              if (!finishedSides[key]) return undefined;
              switch (key) {
                case "voorkant":
                case "achterkant":
                  return parseInt(length) / 1000;
                case "links":
                case "rechts":
                  return parseInt(width) / 1000;
                default:
                  return undefined;
              }
            })
            .filter((val) => val);
          break;
        default:
          sidesLength = Object.keys(finishedSides || {})
            .map((key) => {
              if (!finishedSides[key]) return undefined;
              switch (key) {
                case "voorkant":
                case "achterkant":
                  return parseInt(length) / 1000;
                case "links":
                case "rechts":
                  return parseInt(width) / 1000;
                default:
                  return undefined;
              }
            })
            .filter((val) => val);
      }

      const priceSidesFinished = sidesLength.reduce((a, b) => a + b, 0) * (doubled ? 75 : 16.7);

      totalPrice.finished += priceSidesFinished;
      workSheetEdgePrices[worksheetKey] = {
        finished: priceSidesFinished,
        doubled,
      };
    });

    // Resolve the promise
    resolve([workSheetEdgePrices, totalPrice]);
  });

export const calculateLShapeVerticalEdgePrices = (worksheets) =>
  new Promise((resolve) => {
    const workSheetEdgePrices = {};
    const totalPrice = {
      finished: 0,
    };
    Object.keys(worksheets).forEach((worksheetKey) => {
      const { finishedSides, length, width, doubled } =
        worksheets[worksheetKey] || {};
      let sidesLength = [];

      // Calculate the prices for polished edges
      switch (worksheetKey) {
        case "worksheet0":
          sidesLength = Object.keys(finishedSides || {})
            .map((key) => {
              if (!finishedSides[key]) return undefined;
              switch (key) {
                case "voorkant":
                case "achterkant":
                  return parseInt(length) / 1000 > 0
                    ? parseInt(length) / 1000
                    : 0;
                case "links":
                  return parseInt(width) / 1000 > 0
                    ? parseInt(width) / 1000
                    : 0;
                case "rechts":
                  if (worksheets?.worksheet1?.length) {
                    return (parseInt(width) -
                      parseInt(worksheets?.worksheet1?.length)) /
                      1000 >
                      0
                      ? parseInt(width) / 1000
                      : 0;
                  }
                  return parseInt(width) / 1000 > 0
                    ? parseInt(width) / 1000
                    : 0;
                default:
                  return undefined;
              }
            })
            .filter((val) => val);
          break;
        // Add up the prices from an array to total price
        case "worksheet1":
          sidesLength = Object.keys(finishedSides || {})
            .map((key) => {
              if (!finishedSides[key]) return undefined;
              switch (key) {
                case "links":
                case "rechts":
                  return parseInt(width) / 1000 > 0
                    ? parseInt(width) / 1000
                    : 0;
                case "voorkant":
                  if (worksheets?.worksheet0?.width) {
                    return (parseInt(length) -
                      parseInt(worksheets?.worksheet0?.width)) /
                      1000 >
                      0
                      ? parseInt(length) / 1000
                      : 0;
                  }
                  return parseInt(length) / 1000 > 0
                    ? parseInt(length) / 1000
                    : 0;
                case "achterkant":
                  return parseInt(length) / 1000 > 0
                    ? parseInt(length) / 1000
                    : 0;
                default:
                  return undefined;
              }
            })
            .filter((val) => val);
          break;
        default:
          sidesLength = Object.keys(finishedSides || {})
            .map((key) => {
              if (!finishedSides[key]) return undefined;
              switch (key) {
                case "voorkant":
                case "achterkant":
                  return parseInt(length) / 1000;
                case "links":
                case "rechts":
                  return parseInt(width) / 1000;
                default:
                  return undefined;
              }
            })
            .filter((val) => val);
      }

      const priceSidesFinished = sidesLength.reduce((a, b) => a + b, 0) * (doubled ? 75 : 16.7);

      totalPrice.finished += priceSidesFinished;

      workSheetEdgePrices[worksheetKey] = {
        finished: priceSidesFinished,
        doubled,
      };
    });

    // Resolve the promise
    resolve([workSheetEdgePrices, totalPrice]);
  });

export const calculateLShapeHorizontalEdgePrices = (worksheets) =>
  new Promise((resolve) => {
    const workSheetEdgePrices = {};
    const totalPrice = {
      finished: 0,
    };
    Object.keys(worksheets).forEach((worksheetKey) => {
      const { finishedSides, length, width, doubled } =
        worksheets[worksheetKey] || {};
      let sidesLength = [];

      // Calculate the prices for polished edges
      switch (worksheetKey) {
        case "worksheet0":
          sidesLength = Object.keys(finishedSides || {})
            .map((key) => {
              if (!finishedSides[key]) return undefined;
              switch (key) {
                case "achterkant":
                  return parseInt(length) / 1000 > 0
                    ? parseInt(length) / 1000
                    : 0;
                case "voorkant":
                  if (worksheets?.worksheet1?.width) {
                    return (parseInt(length) -
                      parseInt(worksheets?.worksheet1?.width)) /
                      1000 >
                      0
                      ? parseInt(length) / 1000
                      : 0;
                  }
                  return parseInt(length) / 1000 > 0
                    ? parseInt(length) / 1000
                    : 0;
                case "links":
                  return parseInt(width) / 1000 > 0
                    ? parseInt(width) / 1000
                    : 0;
                case "rechts":
                  return parseInt(width) / 1000 > 0
                    ? parseInt(width) / 1000
                    : 0;
                default:
                  return undefined;
              }
            })
            .filter((val) => val);
          break;
        // Add up the prices from an array to total price
        case "worksheet1":
          sidesLength = Object.keys(finishedSides || {})
            .map((key) => {
              if (!finishedSides[key]) return undefined;
              switch (key) {
                case "links":
                  if (worksheets?.worksheet0?.length) {
                    return (parseInt(width) -
                      parseInt(worksheets?.worksheet0?.length)) /
                      1000 >
                      0
                      ? (parseInt(width) -
                          parseInt(worksheets?.worksheet0?.length)) /
                          1000
                      : 0;
                  }
                  return parseInt(width) / 1000 > 0
                    ? parseInt(width) / 1000
                    : 0;
                case "rechts":
                  return parseInt(width) / 1000 > 0
                    ? parseInt(width) / 1000
                    : 0;
                case "voorkant":
                  return parseInt(length) / 1000 > 0
                    ? parseInt(length) / 1000
                    : 0;
                case "achterkant":
                  return parseInt(length) / 1000 > 0
                    ? parseInt(length) / 1000
                    : 0;
                default:
                  return undefined;
              }
            })
            .filter((val) => val);
          break;
        default:
          sidesLength = Object.keys(finishedSides || {})
            .map((key) => {
              if (!finishedSides[key]) return undefined;
              switch (key) {
                case "voorkant":
                case "achterkant":
                  return parseInt(length) / 1000;
                case "links":
                case "rechts":
                  return parseInt(width) / 1000;
                default:
                  return undefined;
              }
            })
            .filter((val) => val);
          break;
      }

      const priceSidesFinished = sidesLength.reduce((a, b) => a + b, 0) * (doubled ? 75 : 16.7);

      totalPrice.finished += priceSidesFinished;
      workSheetEdgePrices[worksheetKey] = {
        finished: priceSidesFinished,
        doubled,
      };
    });

    // Resolve the promise
    resolve([workSheetEdgePrices, totalPrice]);
  });

export const calculateUShapeHorizontalEdgePrices = (worksheets) =>
  new Promise((resolve) => {
    const workSheetEdgePrices = {};
    const totalPrice = {
      finished: 0,
    };
    Object.keys(worksheets).forEach((worksheetKey) => {
      const { finishedSides, length, width, doubled } =
        worksheets[worksheetKey] || {};
      let sidesLength = [];

      // Calculate the prices for polished edges
      switch (worksheetKey) {
        case "worksheet0":
          sidesLength = Object.keys(finishedSides || {})
            .map((key) => {
              if (!finishedSides[key]) return undefined;
              switch (key) {
                case "links":
                  return parseInt(width) / 1000 > 0
                    ? parseInt(width) / 1000
                    : 0;
                case "rechts":
                  if (worksheets?.worksheet1?.length) {
                    return (parseInt(width) -
                      parseInt(worksheets?.worksheet1?.length)) /
                      1000 >
                      0
                      ? parseInt(width) / 1000
                      : 0;
                  }
                  return parseInt(width) / 1000 > 0
                    ? parseInt(width) / 1000
                    : 0;
                case "voorkant":
                  return parseInt(length) / 1000 > 0
                    ? parseInt(length) / 1000
                    : 0;
                case "achterkant":
                  return parseInt(length) / 1000 > 0
                    ? parseInt(length) / 1000
                    : 0;
                default:
                  return undefined;
              }
            })
            .filter((val) => val);
          break;
        // Add up the prices from an array to total price
        case "worksheet1":
          sidesLength = Object.keys(finishedSides || {})
            .map((key) => {
              if (!finishedSides[key]) return undefined;
              switch (key) {
                case "voorkant":
                  if (
                    worksheets?.worksheet0?.width &&
                    worksheets?.worksheet2?.width
                  ) {
                    return (parseInt(length) -
                      parseInt(worksheets?.worksheet0?.width) -
                      parseInt(worksheets?.worksheet2?.width)) /
                      1000 >
                      0
                      ? parseInt(length) / 1000
                      : 0;
                  } else if (worksheets?.worksheet0?.width) {
                    return (parseInt(length) -
                      parseInt(worksheets?.worksheet0?.width)) /
                      1000 >
                      0
                      ? parseInt(length) / 1000
                      : 0;
                  } else if (worksheets?.worksheet2?.width) {
                    return (parseInt(length) -
                      parseInt(worksheets?.worksheet2?.width)) /
                      1000 >
                      0
                      ? parseInt(length) / 1000
                      : 0;
                  }
                  return parseInt(length) / 1000 > 0
                    ? parseInt(length) / 1000
                    : 0;
                case "achterkant":
                  return parseInt(length) / 1000 > 0
                    ? parseInt(length) / 1000
                    : 0;
                case "links":
                  return parseInt(width) / 1000 > 0
                    ? parseInt(width) / 1000
                    : 0;
                case "rechts":
                  return parseInt(width) / 1000 > 0
                    ? parseInt(width) / 1000
                    : 0;
                default:
                  return undefined;
              }
            })
            .filter((val) => val);
          break;
        case "worksheet2":
          sidesLength = Object.keys(finishedSides || {})
            .map((key) => {
              if (!finishedSides[key]) return undefined;
              switch (key) {
                case "rechts":
                  return parseInt(width) / 1000 > 0
                    ? parseInt(width) / 1000
                    : 0;
                case "links":
                  if (worksheets?.worksheet1?.length) {
                    return (parseInt(width) -
                      parseInt(worksheets?.worksheet1?.length)) /
                      1000 >
                      0
                      ? parseInt(width) / 1000
                      : 0;
                  }
                  return parseInt(width) / 1000 > 0
                    ? parseInt(width) / 1000
                    : 0;
                case "voorkant":
                  return parseInt(length) / 1000 > 0
                    ? parseInt(length) / 1000
                    : 0;
                case "achterkant":
                  return parseInt(length) / 1000 > 0
                    ? parseInt(length) / 1000
                    : 0;
                default:
                  return undefined;
              }
            })
            .filter((val) => val);
          break;
        default:
          sidesLength = Object.keys(finishedSides || {})
            .map((key) => {
              if (!finishedSides[key]) return undefined;
              switch (key) {
                case "voorkant":
                case "achterkant":
                  return parseInt(length) / 1000;
                case "links":
                case "rechts":
                  return parseInt(width) / 1000;
                default:
                  return undefined;
              }
            })
            .filter((val) => val);
        // Add up the prices from an array to total price
      }

      const priceSidesFinished = sidesLength.reduce((a, b) => a + b, 0) * (doubled ? 75 : 16.7);

      totalPrice.finished += priceSidesFinished;
      workSheetEdgePrices[worksheetKey] = {
        finished: priceSidesFinished,
        doubled,
      };
    });
    // Resolve the promise
    resolve([workSheetEdgePrices, totalPrice]);
  });

export const calculateUShapeVerticalEdgePrices = (worksheets) =>
  new Promise((resolve) => {
    const totalPrice = {
      finished: 0,
    };
    const workSheetEdgePrices = {};
    Object.keys(worksheets).forEach((worksheetKey) => {
      const { finishedSides, length, width, doubled } =
        worksheets[worksheetKey] || {};
      let sidesLength = [];
      // Calculate the prices for polished edges
      switch (worksheetKey) {
        case "worksheet0":
          sidesLength = Object.keys(finishedSides || {})
            .map((key) => {
              if (!finishedSides[key]) return undefined;
              switch (key) {
                case "links":
                case "rechts":
                  return parseInt(width) / 1000 > 0
                    ? parseInt(width) / 1000
                    : 0;
                case "achterkant":
                  return parseInt(length) / 1000 > 0
                    ? parseInt(length) / 1000
                    : 0;
                case "voorkant":
                  if (worksheets?.worksheet1?.width) {
                    return (parseInt(length) -
                      parseInt(worksheets?.worksheet1?.width)) /
                      1000 >
                      0
                      ? parseInt(length) / 1000
                      : 0;
                  }
                  return parseInt(length) / 1000 > 0
                    ? parseInt(length) / 1000
                    : 0;
                default:
                  return undefined;
              }
            })
            .filter((val) => val);
          break;
        // Add up the prices from an array to total price
        case "worksheet1":
          sidesLength = Object.keys(finishedSides || {})
            .map((key) => {
              if (!finishedSides[key]) return undefined;
              switch (key) {
                case "voorkant":
                case "achterkant":
                  return parseInt(length) / 1000 > 0
                    ? parseInt(length) / 1000
                    : 0;
                case "links":
                  if (worksheets?.worksheet0?.length) {
                    return (parseInt(width) -
                      parseInt(worksheets?.worksheet0?.length)) /
                      1000 >
                      0
                      ? parseInt(width) / 1000
                      : 0;
                  }
                  return parseInt(width) / 1000 > 0
                    ? parseInt(width) / 1000
                    : 0;
                case "rechts":
                  if (worksheets?.worksheet2?.length) {
                    return (parseInt(width) -
                      parseInt(worksheets?.worksheet2?.length)) /
                      1000 >
                      0
                      ? parseInt(width) / 1000
                      : 0;
                  }
                  return parseInt(width) / 1000 > 0
                    ? parseInt(width) / 1000
                    : 0;
                default:
                  return undefined;
              }
            })
            .filter((val) => val);
          break;
        case "worksheet2":
          sidesLength = Object.keys(finishedSides || {})
            .map((key) => {
              if (!finishedSides[key]) return undefined;
              switch (key) {
                case "links":
                case "rechts":
                  return parseInt(width) / 1000 > 0
                    ? parseInt(width) / 1000
                    : 0;
                case "voorkant":
                  if (worksheets?.worksheet1?.width) {
                    return (parseInt(length) -
                      parseInt(worksheets?.worksheet1?.width)) /
                      1000 >
                      0
                      ? parseInt(length) / 1000
                      : 0;
                  }
                  return parseInt(length) / 1000 > 0
                    ? parseInt(length) / 1000
                    : 0;
                case "achterkant":
                  return parseInt(length) / 1000 > 0
                    ? parseInt(length) / 1000
                    : 0;
                default:
                  return undefined;
              }
            })
            .filter((val) => val);
          break;
        default:
          sidesLength = Object.keys(finishedSides || {})
            .map((key) => {
              if (!finishedSides[key]) return undefined;
              switch (key) {
                case "voorkant":
                case "achterkant":
                  return parseInt(length) / 1000;
                case "links":
                case "rechts":
                  return parseInt(width) / 1000;
                default:
                  return undefined;
              }
            })
            .filter((val) => val);
      }

      const priceSidesFinished = sidesLength.reduce((a, b) => a + b, 0) * (doubled ? 75 : 16.7);

      totalPrice.finished += priceSidesFinished;
      workSheetEdgePrices[worksheetKey] = {
        finished: priceSidesFinished,
        doubled,
      };
    });

    // Resolve the promise
    resolve([workSheetEdgePrices, totalPrice]);
  });

export const calculateEdgePrices = (type, worksheets) =>
  new Promise(async (resolve) => {
    switch (type) {
      case "SINGLE":
        return resolve(await calculateSingleEdgePrices(worksheets));
      case "L_SHAPE_HORIZONTAL":
        return resolve(await calculateLShapeHorizontalEdgePrices(worksheets));
      case "L_SHAPE_VERTICAL":
        return resolve(await calculateLShapeVerticalEdgePrices(worksheets));
      case "U_SHAPE_HORIZONTAL":
        return resolve(await calculateUShapeHorizontalEdgePrices(worksheets));
      case "U_SHAPE_VERTICAL":
        return resolve(await calculateUShapeVerticalEdgePrices(worksheets));
      default:
        resolve([{}, { finished: 0 }]);
        break;
    }
  });

export const calculateSurfacePrices = (worksheets, material) =>
  new Promise((resolve) => {
    const worksheetPrices = {};
    let totalPrice = 0;
    const materialPrice =
      materials[material?.categoryKey]?.items?.[material?.materialKey]?.price;
    if (!materialPrice) resolve([{}, 0]);
    const worksheetSizes = Object.keys(worksheets).map((key) => ({
      key,
      width: !isNaN(parseInt(worksheets[key]?.width))
        ? parseInt(worksheets[key]?.width)
        : undefined,
      length: !isNaN(parseInt(worksheets[key]?.length))
        ? parseInt(worksheets[key]?.length)
        : undefined,
    }));

    worksheetSizes.forEach((item, i) => {
      const { width, length, key } = item || {};
      if (width && length) {
        worksheetPrices[key] =
          (((width / 1000) * length) / 1000) * materialPrice;
        totalPrice += (((width / 1000) * length) / 1000) * materialPrice;
      }
    });
    resolve([worksheetPrices, totalPrice]);
  });

export const calculateSurfacePriceKitchenIsland = (kitchenIsland, material) =>
  new Promise((resolve) => {
    let totalPrice = 0;
    const materialPrice =
      materials[material?.categoryKey]?.items?.[material?.materialKey]?.price;
    if (!materialPrice) resolve([{}, 0]);
    const worksheetSize = {
      width: !isNaN(parseInt(kitchenIsland?.width))
        ? parseInt(kitchenIsland?.width)
        : undefined,
      length: !isNaN(parseInt(kitchenIsland?.length))
        ? parseInt(kitchenIsland?.length)
        : undefined,
    };
    const { width, length } = worksheetSize || {};
    if (width && length) {
      totalPrice = (((width / 1000) * length) / 1000) * materialPrice;
    }
    resolve(totalPrice);
  });

export const calculateSinkPrices = (worksheets) =>
  new Promise((resolve) => {
    const sinks = {};
    const sinksByType = {};
    const sinksPerWorksheet = {};
    let discountApplied = false;
    let totalPrice = 0;
    Object.keys(worksheets).forEach((key) => {
      const tempSinks = worksheets[key]?.addendums?.filter(
        (item) => item.type === "sink"
      );
      if (tempSinks?.length > 0) {
        sinks[key] = tempSinks;
      }
      if (!sinks[key]) return;
      sinks[key].forEach((sinkKey) => {
        const tempItem = sinkTypes[sinkKey.item];
        tempItem.worksheet = key;
        if (!sinksByType[sinkTypes[sinkKey.item].type])
          sinksByType[sinkTypes[sinkKey.item].type] = [];
        sinksByType[sinkTypes[sinkKey.item].type].push(tempItem);
        const tempSink = { ...tempItem };
        if (discountApplied) {
          tempSink.actualPrice = tempSink.price;
          tempSink.discounted = false;
          if (!sinksPerWorksheet[key]) sinksPerWorksheet[key] = [];
          sinksPerWorksheet[key].push(tempSink);
          totalPrice += tempSink.price;
        } else {
          if (tempSink.discountPrice && tempSink.discountPrice < tempSink.price) {
            tempSink.discounted = true;
            tempSink.actualPrice = tempSink.discountPrice;
            discountApplied = true;
          } else {
            tempSink.discounted = false;
            tempSink.actualPrice = tempSink.price;
          }
          if (!sinksPerWorksheet[key]) sinksPerWorksheet[key] = [];
          sinksPerWorksheet[key].push(tempSink);
          totalPrice += tempSink.actualPrice;
        }
      });
    });
    resolve([sinksPerWorksheet, totalPrice]);
  });

export const calculateStovePrices = (worksheets, sinks) =>
  new Promise((resolve) => {
    let totalPrice = 0;
    const stovesPerWorksheet = {};

    Object.keys(worksheets).forEach((key) => {
      const tempStoves = worksheets[key]?.addendums?.filter(
        (item) => item.type === "stove"
      );
      tempStoves.forEach(() => {
        if (!stovesPerWorksheet[key]) stovesPerWorksheet[key] = [];
        stovesPerWorksheet[key].push(100);
        totalPrice += 100;
      });
    });

    resolve([stovesPerWorksheet, totalPrice]);
  });

export const calculateElektricityPrices = (worksheets, sinks) =>
  new Promise((resolve) => {
    let totalPrice = 0;
    const stovesPerWorksheet = {};

    Object.keys(worksheets).forEach((key) => {
      const tempStoves = worksheets[key]?.addendums?.filter(
        (item) => item.type === "outlet"
      );
      tempStoves.forEach(() => {
        if (!stovesPerWorksheet[key]) stovesPerWorksheet[key] = [];
        stovesPerWorksheet[key].push(55);
        totalPrice += 55;
      });
    });

    resolve([stovesPerWorksheet, totalPrice]);
  });

export const calculateDeliveryPrice = (travelDistance, installed, delivered) =>
  new Promise((resolve) => {
    if (delivered && !installed) {
      if (travelDistance < 25) {
        return resolve(deliveryPrices.LESS_THAN_25);
      }
      if (travelDistance >= 25 && travelDistance < 50) {
        return resolve(deliveryPrices.BETWEEN_25_AND_50);
      }
      if (travelDistance >= 50 && travelDistance < 75) {
        return resolve(deliveryPrices.BETWEEN_50_AND_75);
      }
      if (travelDistance >= 75 && travelDistance < 100) {
        return resolve(deliveryPrices.BETWEEN_75_AND_100);
      }
      return resolve(0);
    } else if (installed) {
      if (travelDistance < 25) {
        return resolve(deliveryWithInstallPrices.LESS_THAN_25);
      }
      if (travelDistance >= 25 && travelDistance < 50) {
        return resolve(deliveryWithInstallPrices.BETWEEN_25_AND_50);
      }
      if (travelDistance >= 50 && travelDistance < 75) {
        return resolve(deliveryWithInstallPrices.BETWEEN_50_AND_75);
      }
      if (travelDistance >= 75 && travelDistance < 100) {
        return resolve(deliveryWithInstallPrices.BETWEEN_75_AND_100);
      }
      return resolve(0);
    } else resolve(0);
  });

export const calculateInstallPrice = (sizes, hasKitchenIsland, installed) =>
  new Promise((resolve) => {
    if (!installed) return resolve(0);
    let price = 0;
    price += installPrices[Object.keys(sizes)?.length] || 0;
    if (hasKitchenIsland) price += installPrices.island;
    resolve(price);
  });

export const calculateMeasurePrices = (travelDistance, measured) =>
  new Promise((resolve) => {
    if (!measured) resolve(0);
    if (travelDistance < 25) {
      return resolve(measurePrices.LESS_THAN_25);
    }
    if (travelDistance >= 25 && travelDistance < 50) {
      return resolve(measurePrices.BETWEEN_25_AND_50);
    }
    if (travelDistance >= 50 && travelDistance < 75) {
      return resolve(measurePrices.BETWEEN_50_AND_75);
    }
    if (travelDistance >= 75 && travelDistance < 100) {
      return resolve(measurePrices.BETWEEN_75_AND_100);
    }
    return resolve(0);
  });

export const calculatePrice = ({
  kitchenType,
  sizes,
  material,
  kitchenIsland,
  travelDistance,
  measured,
  installed,
  delivered,
}) =>
  new Promise(async (resolve) => {
    let tempSizes = {};
    if (sizes) {
      tempSizes = { ...tempSizes, ...sizes };
    }
    if (kitchenIsland) {
      tempSizes = { ...tempSizes, kitchenIsland };
    }
    const [edgesPerWorksheet, totalEdgePrices] = await calculateEdgePrices(
      kitchenType,
      tempSizes
    );
    const [surfacesPerWorksheet, totalSurfacesPrices] =
      await calculateSurfacePrices(tempSizes, material);
    const [sinksPerWorksheet, totalSinksPrices] = await calculateSinkPrices(
      tempSizes,
      material
    );
    const [stovesPerWorksheet, totalStovePrices] = await calculateStovePrices(
      tempSizes,
      material
    );
    const [elektricityPerWorksheet, totalElektricityPrices] =
      await calculateElektricityPrices(tempSizes, material);
    const deliveryPrice = await calculateDeliveryPrice(
      travelDistance,
      installed,
      delivered
    );
    const installPrice = await calculateInstallPrice(
      sizes,
      !!kitchenIsland,
      installed
    );
    const measurePrice = await calculateMeasurePrices(travelDistance, measured);
    let total =
      totalSurfacesPrices +
      totalSinksPrices +
      totalStovePrices +
      totalElektricityPrices +
      totalEdgePrices.finished +
      deliveryPrice +
      installPrice +
      measurePrice;
    const totalServicePrice = deliveryPrice + installPrice + measurePrice;
    const totalWorksheetPrices = {};
    Object.keys(surfacesPerWorksheet).forEach((key) => {
      let tempTotal = 0;
      tempTotal +=
        edgesPerWorksheet[key]?.finished;
      tempTotal += surfacesPerWorksheet[key];
      tempTotal +=
        elektricityPerWorksheet[key]?.length > 0
          ? elektricityPerWorksheet[key]?.reduce((a, b) => a + b, 0)
          : 0;
      tempTotal +=
        stovesPerWorksheet[key]?.length > 0
          ? stovesPerWorksheet[key]?.reduce((a, b) => a + b, 0)
          : 0;
      if (sinksPerWorksheet[key]) {
        sinksPerWorksheet[key].forEach(
          (item) => (tempTotal += item.actualPrice)
        );
      }
      totalWorksheetPrices[key] = tempTotal;
    });
    resolve([
      {
        totalWorksheetPrices,
        edgesPerWorksheet,
        surfacesPerWorksheet,
        sinksPerWorksheet,
        stovesPerWorksheet,
        elektricityPerWorksheet,
        deliveryPrice,
        installPrice,
        measurePrice,
        totalServicePrice,
      },
      total,
    ]);
  });
