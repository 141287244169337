import React from "react";
import styled from "styled-components";
import KitchenOption from "./KitchenOption";

const TypeSelector = ({ typeState, kitchenTypes }) => {
  const [kitchenType, setKitchenType] = typeState;
  return (
    <Container>
      <Title>Stap 1: Kies je keukenvorm</Title>
      <div>
        {Object.keys(kitchenTypes).map(key => (
          <KitchenOption
            onChange={setKitchenType}
            kitchenType={kitchenType}
            key={key}
            img={kitchenTypes[key]?.img}
            kitchenKey={key}
            name={kitchenTypes[key]?.name}
          />
        ))}
      </div>
    </Container>
  );
};

const Title = styled.h2`
  font-size: 2.4rem;
  margin-bottom: 1rem;
  font-weight: bold;
  display: inline-block;
`;

const Container = styled.div`
  & > div {  
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 2rem;
    margin-bottom: 4rem;

    @media only screen and (max-width: 1300px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media only screen and (max-width: 950px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 650px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

export default TypeSelector;
