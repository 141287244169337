import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from 'history';
import KitchenPlanner from "./pages/index";
import OfferSent from "./pages/OfferSent";
import "./assets/reset.css";
import "./assets/index.css";

function App() {
  const history = createBrowserHistory(); 
  return (
    <Router history={history}>
      <Switch>
        <Route path="/offerte-verzonden" component={OfferSent} exact />
        <Route path="/" component={KitchenPlanner} />
      </Switch>
    </Router>
  );
}

export default App;
