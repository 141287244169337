import React from "react";
import styled, { keyframes } from "styled-components";
import { isEqual } from "lodash";

import KitchenMaterialSelectorItem from "./KitchenMaterialSelectorItem";
const KitchenMaterialSelectorContainer = ({
  materials,
  materialState,
  className,
}) => {
  const [materialType, setMaterialType] = materialState;
  return (
    <Container className={className}>
      <Title>Stap 2: Kies je materiaal</Title>
      <MaterialContainer>
        {materials?.map((category, i) =>
          category?.items.map((material, j) => (
            <KitchenMaterialSelectorItem
              material={material}
              onChange={setMaterialType}
              checked={isEqual(materialType, {
                categoryKey: category.categoryKey,
                materialKey: material.materialKey,
              })}
              materialType={materialType}
              materialKey={material.materialKey}
              categoryKey={category.categoryKey}
              key={`Material ${i + 1}/${j + 1}`}
              title={category.name}
              materialState={materialState}
            />
          ))
        )}
      </MaterialContainer>
    </Container>
  );
};

const Title = styled.h2`
  font-size: 2.4rem;
  margin-bottom: 1rem;
  font-weight: bold;
  display: inline-block;
`;

const FadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const MaterialContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2rem;
  margin-bottom: 4rem;

  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media screen and (max-width: 940px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 650px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Container = styled.div`
  animation: ${FadeIn} 300ms ease;
  transition: opacity 0.3s ease;
  &.item-enter {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }
  &.item-enter-active {
    opacity: 1;
    max-height: 200rem;
    transition: opacity 200ms 400ms ease-in, visibility 200ms 400ms ease-in, max-height 0s 400ms;
  }
  &.item-exit {
    opacity: 1;
    visibility: visible;
  }
  &.item-exit-active {
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease-in, visibility 200ms ease-in;
  }
`;

export default KitchenMaterialSelectorContainer;
