import React from "react";
import styled, { keyframes } from "styled-components";
import KitchenIslandForm from './KitchenIslandForm';
const KitchenIslandComponent = ({
  kitchenType,
  hasKitchenIslandState,
  kitchenIslandState,
  sinkTypes,
  className,
  materialMaxSizes,
}) => {
  const [hasKitchenIsland, setHasKitchenIsland] = hasKitchenIslandState;
  const [kitchenIsland] = kitchenIslandState;

  const handleSelect = e => {
    setHasKitchenIsland(e.target.value === 'true');
  };

  return (
    <Container className={className}>
      <Title>Wenst u een blad voor een keukeneiland?</Title>
      <QuestionContainer>
        <HasKitchenIslandLabel checked={hasKitchenIsland} htmlFor="has_kitchen_island">
          <Input type="radio" onChange={e => handleSelect(e)} checked={hasKitchenIsland} value={true} name="has_kitchen_island" id="has_kitchen_island" />
          Ja
        </HasKitchenIslandLabel>
        <HasKitchenIslandLabel checked={!hasKitchenIsland} htmlFor="has_no_kitchen_island">
          <Input type="radio" onChange={e => handleSelect(e)} checked={!hasKitchenIsland} value={false} name="has_kitchen_island" id="has_no_kitchen_island" />
          Nee
        </HasKitchenIslandLabel>
      </QuestionContainer>
      {hasKitchenIsland && (
        <WorkSheetContainer>
          <KitchenIslandForm
            workSheet={kitchenIsland}
            materialMaxSizes={materialMaxSizes}
            worksheetImage={kitchenType?.imgisland}
            id={`kitchenIsland`}
            sinkTypes={sinkTypes}
            kitchenIslandState={kitchenIslandState}
          />
        </WorkSheetContainer>
      )}
    </Container>
  );
};

const Input = styled.input``;

const WorkSheetContainer = styled.div`
  display: flex;
  margin: -1rem;
  flex-wrap: wrap;
  @media only screen and (max-width: 1400px) {
    & > div {
      width: calc(50% - 2rem);
    }
  }

  @media only screen and (max-width: 900px) {
    & > div {
      width: 100%;
    }
  }
`;

const Title = styled.h2`
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 1.6rem;
`;

const HasKitchenIslandLabel = styled.label`
  border: 1px solid gray;
  padding: 1rem 2rem;
  margin: .5rem;
  cursor: pointer;
  border-radius: 3px;
  border-color: ${({ checked }) => checked ? '#366399' : 'gray'};
  color: ${({ checked }) => checked ? 'white' : 'gray'};
  background-color: ${({ checked }) => checked ? '#366399' : 'none'};
  transition: all .2s ease, transform 0.1s ease;
  
  &:active {
    transform: scale(0.9);
  }

  &:hover {
    background-color: ${({ checked }) => checked ? '#366399' : '#7B9FC9'};
    color: white;
  }

  & > input {
    display: none;
  }
`;

const QuestionContainer = styled.div`
  margin: 1rem -.5rem 2rem -.5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
`;

const FadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  animation: ${FadeIn} 300ms ease;
  transition: opacity 0.3s ease;
  &.item-enter {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }
  &.item-enter-active {
    opacity: 1;
    max-height: 200rem;
    transition: opacity 200ms 400ms ease-in, visibility 200ms 400ms ease-in, max-height 0s 400ms;
  }
  &.item-exit {
    opacity: 1;
    visibility: visible;
  }
  &.item-exit-active {
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease-in, visibility 200ms ease-in;
  }
`;

export default KitchenIslandComponent;
