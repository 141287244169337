import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { string, func, node, object } from 'prop-types';

function textColor(selected) {
  if (selected) {
    return 'white';
  }
  return 'gray';
}

const RadioButton = styled.label`
  font-family: ${({ theme }) => theme.font};
  box-sizing: border-box;
  font-size: 1.6rem;
  position: relative;
  width: 100%;
  
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ selected }) => selected ? `border: 2px solid #366399; box-shadow: 0 0 4px gray;` : `border: 1px solid rgba(200,200,200,0.8)`};
  transform: scale(${({ checked }) => checked ? 1.02 : 1});
  box-sizing: border-box;
  border-radius: 5px;
  color: ${({ selected }) => textColor(selected)};
  transition: all .2s ease;
  margin-bottom: 1rem;

  &:hover {
    box-shadow: 0 0 4px gray;
    border-color: ${({ selected }) => selected ? `#366399` : `#7B9FC9`};
    transform: scale(${({ selected }) => selected ? 1.02 : 1.01});
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 3px;
    object-fit: contain;
  }

  input {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
`;

const Container = styled.div`
  margin-bottom: 2rem;
`;

RadioButton.displayName = 'RadioButton';

const ImageSelectBox = forwardRef(
  ({ selected, label, value, icon, onChange, name, children, ...otherProps }, ref) => {
    const handleChange = e => {
      e.persist();
      onChange({ name: name, value: e.target.value });
    };

    return (
      <Container>
        <RadioButton selected={selected === value} label={label} {...otherProps}>
          {icon}
          <input
            ref={ref}
            type="radio"
            checked={selected === value}
            value={value}
            onChange={handleChange}
            name={name}
          />
        </RadioButton>
        {children}
      </Container>
    );
  },
);

ImageSelectBox.displayName = 'ImageSelectBox';

ImageSelectBox.defaultProps = {
  label: '',
  onChange: () => {},
  name: 'radios',
  otherProps: {},
  selected: '',
};

ImageSelectBox.propTypes = {
  selected: string,
  label: string,
  value: string,
  icon: node,
  onChange: func,
  mode: string,
  name: string,
  otherProps: object,
};

export default ImageSelectBox;
