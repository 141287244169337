import React from "react";
import styled from "styled-components";
import Check from './checkmark';

const KitchenOption = ({ onChange, name, image, kitchenKey, kitchenType, img }) => {
  const handleChange = (e) => {
    const value = e.target.checked;
    if (value) onChange(kitchenKey);
  };
  return (
    <Label checked={kitchenType === kitchenKey}>
      <input
        type="radio"
        checked={kitchenType === kitchenKey}
        name="kitchenType"
        onChange={handleChange}
      />
       <BGImg src={img} />
       <ItemsContiner>
        <CheckMark checked={kitchenType === kitchenKey}>
          <StyledCheck checked={kitchenType === kitchenKey} color="white" />
        </CheckMark>
        <TitleContainer>
          <h3>{name}</h3>
        </TitleContainer>
      </ItemsContiner>
    </Label>
  );
};

const BGImg = styled.img`
  height: 20rem;
  width: 100%;
  object-fit: contain;
`;

const ItemsContiner = styled.div`
  display: flex;
  margin-top: 0;
  align-items: center;
  padding: 0.5rem;
  border-radius: 3px;
  border: 1px solid rgba(200,200,200,0.8);
  background-color: rgba(255,255,255,0.8);
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckMark = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  border: 1px solid rgba(200,200,200,0.8);
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-right: 2rem;
  background-color: ${({ checked }) => checked ? `#366399;` : `rgba(0,0,0,0)`};
`;

const StyledCheck = styled(Check)`
  transition: all .2s ease;
  opacity: ${({ checked }) => checked ? '1' : '0'};
`;

const Label = styled.label`
  padding: 0.5rem;
  background-repeat: no-repeat;
  background-color: white;
  box-shadow: 0 0 2px gray;
  ${({ checked }) => checked ? `border: 2px solid #366399; box-shadow: 0 0 4px gray;` : `border: 1px solid rgba(200,200,200,0.8)`};
  transform: scale(${({ checked }) => checked ? 1.02 : 1});
  background-size: contain;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  background-image: url(${({ bg }) => bg});
  transition: all .2s ease;
  background-position: center;


  &:hover {
    box-shadow: 0 0 4px gray;
    border-color: ${({ checked }) => checked ? `#366399` : `#7B9FC9`};
    transform: scale(${({ checked }) => checked ? 1.02 : 1.01});
  }

  & input {
    display: none;
  }
`;

export default KitchenOption;
