import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import useForm from "../hooks/useForm";

import { number } from "../services/validationSchemas";
import Modal from "./Modal";
import SelectBox from "./SelectBox";
import TextInput from "./TextInput";
import AddSinkForm from "./AddSinkForm";
// import AddStoveForm from "./AddStoveForm";
import AddOutletForm from "./AddOutletForm";
import { cloneDeep } from "lodash";

const KitchenWorkSheetForm = ({
  sizesState,
  id,
  title,
  sinkTypes,
  worksheetImage,
  // materialMaxSizes,
}) => {
  const [showSinkModal, setShowSinkModal] = useState(false);
  // const [showStoveModal, setShowStoveModal] = useState(false);
  const [showOutletModal, setShowOutletModal] = useState(false);
  const [sizes, setSizes] = sizesState;
  const [doubledSides, setDoubledSides] = useState(false);
  const [addendums, setAddendums] = useState([]);
  const SignupSchema = Yup.object().shape({
    width: number.required.min(100, "Te klein"),
    length: number.required.min(100, "Te klein"),
  });
  const addendumsState = sizes?.[id]?.addendums;

  const addAddendum = (item) => {
    setAddendums((prevValue) => [...prevValue, item]);
  };

  const deleteAddendum = (e, key) => {
    e.preventDefault();
    setAddendums((prevValue) => {
      const tempValue = cloneDeep(prevValue);
      delete tempValue[key];
      return tempValue.filter((item) => !!item);
    });
  };

  const { handleChange, errors, handleSubmit, values, resetForm } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
  });

  useEffect(() => {
    if (!addendumsState) {
      setAddendums([]);
      resetForm();
    }
  }, [addendumsState, resetForm]);

  const handleSelect = (e) => {
    setDoubledSides(e.target.value === "true");
  };

  useEffect(() => {
    setSizes((prevValue) => {
      const tempSizes = cloneDeep(prevValue);
      if (!tempSizes?.[id]) tempSizes[id] = {};
      const { width, length, links, rechts, voorkant, achterkant } =
        values || {};
      tempSizes[id] = {
        width: width,
        length: length,
        doubled: doubledSides,
        finishedSides: {
          links,
          rechts,
          voorkant,
          achterkant,
        },
        addendums,
      };
      return tempSizes;
    });
  }, [values, addendums, id, setSizes, doubledSides]);

  const typeStringGetter = (type) => {
    switch (type) {
      case "sink":
        return "Spoeltafel";
      case "stove":
        return "Kookplaat";
      case "outlet":
        return "Powerport";
      default:
        return "Uitsparing";
    }
  };

  const addStoveInbouw = () => {
    const payload = {
      type: "stove",
    };
    addAddendum(payload);
  };

  const addElektricityTotem = () => {
    const payload = {
      type: "outlet",
      width: 102,
      length: 102,
    };
    addAddendum(payload);
  };
  return (
    <Container>
      <Modal
        showModal={showSinkModal}
        setShowModal={setShowSinkModal}
        title="Spoeltafel toevoegen"
        canClose
      >
        <AddSinkForm
          state={[showSinkModal, setShowSinkModal]}
          addAddendum={addAddendum}
          sinkTypes={sinkTypes}
        />
      </Modal>
      {/* <Modal
        showModal={showStoveModal}
        setShowModal={setShowStoveModal}
        title="Uitsparing vlakbouw kookplaat toevoegen"
        canClose
      >
        <AddStoveForm
          state={[showStoveModal, setShowStoveModal]}
          addAddendum={addAddendum}
        />
      </Modal> */}
      <Modal
        showModal={showOutletModal}
        setShowModal={setShowOutletModal}
        title="Uitsparing powerport toevoegen"
        canClose
      >
        <AddOutletForm
          state={[showOutletModal, setShowOutletModal]}
          addAddendum={addAddendum}
        />
      </Modal>
      <ImgContainer>
        <Img src={worksheetImage} alt="worksheetimage" />
      </ImgContainer>
      <Title>{title}</Title>
      <Label>Afmetingen</Label>
      <InputContainer>
        <TextInput
          type="number"
          name="length"
          placeholder="Lengte"
          value={values.length}
          onBlur={() => handleSubmit(() => {})}
          onChange={handleChange}
          error={errors.length}
        >
          Lengte in mm
        </TextInput>
        <TextInput
          type="number"
          name="width"
          placeholder="Breedte"
          value={values.width}
          onBlur={() => handleSubmit(() => {})}
          onChange={handleChange}
          error={errors.width}
        >
          Breedte in mm
        </TextInput>
      </InputContainer>

      <Label>Afgewerkte zijden</Label>
      <SidesContainer>
        <SelectBox
          name="links"
          value={values.links}
          label="Links"
          onChange={handleChange}
        />
        <SelectBox
          name="rechts"
          value={values.rechts}
          label="Rechts"
          onChange={handleChange}
        />
        <SelectBox
          name="voorkant"
          value={values.voorkant}
          label="Voorkant"
          onChange={handleChange}
        />
        <SelectBox
          name="achterkant"
          value={values.achterkant}
          label="Achterkant"
          onChange={handleChange}
        />
      </SidesContainer>
      <Label>Verdubbelde randen</Label>
      <QuestionContainer>
        <HasKitchenIslandLabel
          checked={doubledSides}
          htmlFor={`has_doubled_sides${id}`}
        >
          <Input
            type="radio"
            onChange={(e) => handleSelect(e)}
            checked={doubledSides}
            value={true}
            name={`has_doubled_sides${id}`}
            id={`has_doubled_sides${id}`}
          />
          Ja
        </HasKitchenIslandLabel>
        <HasKitchenIslandLabel
          checked={!doubledSides}
          htmlFor={`has_no_doubled_sides${id}`}
        >
          <Input
            type="radio"
            onChange={(e) => handleSelect(e)}
            checked={!doubledSides}
            value={false}
            name={`has_doubled_sides${id}`}
            id={`has_no_doubled_sides${id}`}
          />
          Nee
        </HasKitchenIslandLabel>
      </QuestionContainer>
      <UitsparingContainer>
        <Label>Uitsparingen</Label>
        <AddContainer>
          <AddButton onClick={() => setShowSinkModal(true)}>
            Spoeltafel toevoegen
          </AddButton>
          <AddButton onClick={() => addStoveInbouw(true)}>
            Uitsparing opbouw kookplaat toevoegen
          </AddButton>
          <AddButton onClick={() => addElektricityTotem(true)}>
            Uitsparing powerport toevoegen
          </AddButton>
        </AddContainer>
      </UitsparingContainer>
      {addendums.length > 0 && (
        <UitsparingsLijst>
          <thead>
            <TableHead>
              <TableHeadItem>Type</TableHeadItem>
              <TableHeadItem>Naam</TableHeadItem>
              <TableHeadItem></TableHeadItem>
            </TableHead>
          </thead>
          <tbody>
            {addendums?.map((item, i) => (
              <AddendumItem key={i}>
                <TableItem>{typeStringGetter(item?.type)}</TableItem>
                <TableItem>
                  {sinkTypes[item?.item]?.name || "Uitsparing"}
                </TableItem>
                <TableItem>
                  <DeleteButton onClick={(e) => deleteAddendum(e, i)}>
                    Verwijder
                  </DeleteButton>
                </TableItem>
              </AddendumItem>
            ))}
          </tbody>
        </UitsparingsLijst>
      )}
    </Container>
  );
};

const DeleteButton = styled.button`
  cursor: pointer;
  background-color: #366399;
  transition: all 0.2s ease;
  padding: 5px;
  color: white;
  border-radius: 3px;

  &:hover {
    background-color: #7b9fc9;
  }
`;

const InputContainer = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: 2rem;
  }
`;

const Input = styled.input``;
const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;
const HasKitchenIslandLabel = styled.label`
  border: 1px solid gray;
  padding: 1rem 2rem;
  margin: 0.5rem;
  cursor: pointer;
  border-radius: 3px;
  border-color: ${({ checked }) => (checked ? "#366399" : "gray")};
  color: ${({ checked }) => (checked ? "white" : "gray")};
  background-color: ${({ checked }) => (checked ? "#366399" : "none")};
  transition: all 0.2s ease, transform 0.1s ease;

  &:active {
    transform: scale(0.9);
  }

  &:hover {
    background-color: ${({ checked }) => (checked ? "#366399" : "#7B9FC9")};
    color: white;
  }

  & > input {
    display: none;
  }
`;

const Img = styled.img`
  width: 50%;
  margin: auto;
`;

const QuestionContainer = styled.div`
  margin: 1rem -0.5rem 2rem -0.5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
`;

const TableItem = styled.td`
  padding: 0.5rem 0;
`;
const TableHead = styled.tr`
  font-weight: bold;
`;
const TableHeadItem = styled.th`
  padding: 1rem 0;
`;

const AddendumItem = styled.tr``;

const AddButton = styled.button`
  background-color: #366399;
  padding: 1rem;
  border-radius: 5px;
  margin: 1rem;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    background-color: #7b9fc9;
    color: white;
  }
`;

const UitsparingsLijst = styled.table`
  width: 100%;
  text-align: center;
  margin-top: 2rem;
`;

const AddContainer = styled.div`
  margin: -1rem;
`;

const UitsparingContainer = styled.div`
  margin-top: 3rem;
`;

const Title = styled.h2`
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 2rem;
`;

const Label = styled.h3`
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 1.6rem;
`;

const SidesContainer = styled.div`
  display: flex;
  margin: 0;
  margin-bottom: 2rem;
  flex-wrap: wrap;

  & > label {
    margin-bottom: 1rem;
  }
`;

const Container = styled.div`
  border: 1px solid rgba(200, 200, 200, 0.8);
  border-radius: 3px;
  padding: 2rem;
  box-shadow: 0 0 2px gray;
  background-color: white;
  margin: 1rem;
  width: calc(calc(100% / 3) - 2rem);
`;

export default KitchenWorkSheetForm;
