import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ActionButton from '../components/ActionButton';

const OfferSent = () => {
  const history = useHistory();
  return (
    <Background>
      <TitleContainer>
        <h1>Offerte werd verstuurd</h1>
        <ActionButton onClick={() => history.push('/')}>
          Terug naar start
        </ActionButton>
      </TitleContainer>
    </Background>
  );
};

const Background = styled.div`
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cecece;
`;

const TitleContainer = styled.div`
  padding: 4rem;
  font-size: 4rem;
  font-family: arial, helvetica, sans-serif;
  font-weight: bold;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 0 1rem gray;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > h1 {
    margin-bottom: 3rem;
  }

`;

export default OfferSent;
