import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import useForm from "../hooks/useForm";

import { number } from "../services/validationSchemas";
import ImageSelectBox from "./ImageSelectBox";
import ActionButton from "./ActionButton";
// import TextInput from "./TextInput";

const KitchenWorkSheetForm = ({ addAddendum, state, title, sinkTypes }) => {
  const [, setShowSinkModal] = state || [];
  const SignupSchema = Yup.object().shape({
    sinkId: number.required,
    width: Yup.number().when("sinkId", {
      is: (val) => +val !== 0,
      then: number.required.min(1, "Te klein").max(2000, "Te groot"),
      otherwhise: number.notRequired.min(1, "Te klein").max(2000, "Te groot"),
    }),
    height: Yup.number().when("sinkId", {
      is: (val) => +val !== 0,
      then: number.required.min(1, "Te klein").max(2000, "Te groot"),
      otherwhise: number.notRequired.min(1, "Te klein").max(2000, "Te groot"),
    }),
    // x: number.required.min(1, "te klein").max(2000, "te groot"),
    // y: number.required.min(1, "te klein").max(2000, "te groot"),
  });

  const { handleChange, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
    initialValues: {
      sinkId: "0",
    },
  });

  const onSubmit = () => {
    const { x, y, width, height, sinkId } = values || {};
    const payload = {
      type: "sink",
      width,
      height,
      item: sinkId,
      x,
      y,
    };
    addAddendum(payload);
    setShowSinkModal(false);
  };

  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSubmit);
      }}
    >
      <DiscountInfo>
        De promotie is enkel geldig op de goedkoopste spoeltafel in deze
        bestelling
      </DiscountInfo>
      <SinkTypes>
        {sinkTypes.map((sink, i) => (
          <ImageSelectBox
            name="sinkId"
            onChange={(val) => {
              handleChange({ name: "width", value: sink?.sizes?.x });
              handleChange({ name: "height", value: sink?.sizes?.y });
              handleChange(val);
            }}
            value={String(i)}
            icon={<Img src={sink.image} alt="" />}
            key={i}
            selected={values?.sinkId}
          >
            <SinkHeader>
              <SinkName>{sink.name}</SinkName>
              <SinkPrice discounted={sink?.discountPrice && sink?.discountPrice < sink.price}>
                {sink.price.toLocaleString("nl-BE", {
                  style: "currency",
                  currency: "EUR",
                })}
              </SinkPrice>
            </SinkHeader>
            {/* {sink.type !== 0 && (
              <SinkSizes>
                <p>Afmetingen: {sink.sizes.x}mm - {sink.sizes.y}mm</p>
              </SinkSizes>
            )} */}
            {sink?.discountPrice && sink?.discountPrice < sink.price && (
              <SinkPromo>
                {sink?.promo?.toLocaleString("nl-BE", {
                  style: "currency",
                  currency: "EUR",
                })}
                {" "}
                {sink?.discountPrice?.toLocaleString("nl-BE", {
                  style: "currency",
                  currency: "EUR",
                })}
              </SinkPromo>
            )}
          </ImageSelectBox>
        ))}
      </SinkTypes>
      {/* <Label>Uitsparing</Label>
      <InputContainer>
        <TextInput
          type="number"
          name="width"
          disabled={sinkTypes?.[values.sinkId]?.type !== 0}
          value={values.width}
          onBlur={() => handleSubmit(() => {})}
          onChange={handleChange}
          error={errors.width}
        >
          Breedte (in mm)
        </TextInput>
        <TextInput
          type="number"
          name="height"
          disabled={sinkTypes?.[values.sinkId]?.type !== 0}
          value={values.height}
          onBlur={() => handleSubmit(() => {})}
          onChange={handleChange}
          error={errors.height}
        >
          Lengte (in mm)
        </TextInput>
      </InputContainer> */}
      <ButtonContainer>
        <StyledActionButton>Voeg toe</StyledActionButton>
        <StyledActionButton
          onClick={(e) => {
            e.preventDefault();
            setShowSinkModal(false);
          }}
        >
          Annuleer
        </StyledActionButton>
      </ButtonContainer>
    </Container>
  );
};

const Img = styled.img`
  background-color: #cecece;
`;

const SinkName = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const SinkHeader = styled.div`
  display: flex;
  justify-content: space-between;
  & > div:first-child {
    margin-right: 1rem;
  }
`;

const SinkPrice = styled.div`
  font-weight: bold;
  ${({ discounted }) => discounted && `
    text-decoration: line-through;
    font-weight: 300;
    color: darkgrey;
  ` }
`;

// const SinkSizes = styled.div`
//   margin-bottom: 5px;
// `;

const SinkPromo = styled.div`
  font-weight: bold;
  text-align: right;
`;

// const InputContainer = styled.div`
//   display: flex;

//   & > div:first-child {
//     margin-right: 2rem;
//   }
// `;

const DiscountInfo = styled.p`
  margin-bottom: 2rem;
`;

// const Label = styled.h3`
//   margin-bottom: 1rem;
// `;

const SinkTypes = styled.div`
  display: grid;
  margin-bottom: 2rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: -1rem;
`;

const StyledActionButton = styled(ActionButton)`
  margin: 1rem;
`;

const Container = styled.form``;

export default KitchenWorkSheetForm;
