import React from "react";
import styled from "styled-components";
import Check from './checkmark';

const KitchenWorkSheetForm = ({
  onChange,
  material,
  materialKey,
  categoryKey,
  title,
  checked,
}) => {
  const handleChange = (e) => {
    const value = e.target.checked;
    if (value)
      onChange({
        categoryKey,
        materialKey,
      });
  };
  return (
    <Container bg={material.img} checked={checked}>
      <input
        type="radio"
        checked={checked}
        name="materialType"
        onChange={handleChange}
      />
      <ItemsContiner>
        <CheckMark checked={checked}>
          <StyledCheck checked={checked} color="white" />
        </CheckMark>
        <TitleContainer>
          <h3>{material.name}</h3>
          <p>{title}</p>
        </TitleContainer>
      </ItemsContiner>
    </Container>
  );
};

const ItemsContiner = styled.div`
  display: flex;
  margin-top: 10rem;
  align-items: center;
  padding: 0.5rem;
  border-radius: 3px;
  border: 1px solid rgba(200,200,200,0.8);
  background-color: rgba(255,255,255,0.8);
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckMark = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: ${({ checked }) => checked ? `#366399;` : `rgba(0,0,0,0)`};
  display: flex;
  border: 1px solid rgba(200,200,200,0.8);
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-right: 2rem;
`;

const StyledCheck = styled(Check)`
  transition: all .2s ease;
  opacity: ${({ checked }) => checked ? '1' : '0'};
`;

const Container = styled.label`
  padding: 0.5rem;
  ${({ checked }) => checked ? `border: 2px solid #366399; box-shadow: 0 0 4px gray;` : `border: 1px solid rgba(200,200,200,0.8)`};
  transform: scale(${({ checked }) => checked ? 1.02 : 1});
  border-radius: 3px;
  position: relative;
  box-shadow: 0 0 2px gray;
  cursor: pointer;
  background-image: url(${({ bg }) => bg});
  background-position: center;
  transition: all .2s ease;
  &:hover {
    box-shadow: 0 0 4px gray;
    border-color: ${({ checked }) => checked ? `#366399` : `#7B9FC9`};
    transform: scale(${({ checked }) => checked ? 1.02 : 1.01});
  }

  & input {
    display: none;
  }
`;

export default KitchenWorkSheetForm;
