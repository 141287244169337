const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export async function fetchBaseAPi() {
  return "https://amantnv.be/api/api.php?do=";
}

async function _request(path, method, payload, external) {
  const API = await fetchBaseAPi();
  const url = external ? path : API + path;
  let req;
  console.log(`[API REQUEST] ${method} ${path}`);
  if (payload === null) {
    req = await fetch(`${url}`, {
      method,
      headers,
    });
  } else {
    req = await fetch(`${url}`, {
      method,
      headers,
      body: payload instanceof FormData ? payload : JSON.stringify(payload),
    });
  }

  console.log(`[API RESPONSE] ${method} ${path} status: ${req.status}`);
  let resp;
  if (req.status !== 204) {
    resp = await req.json();
  } else {
    resp = null;
  }
  return [resp, req.status];
}

export async function request(path, method, payload = null) {
  let [resp, status] = await _request(path, method, payload);
  return [resp, status];
}

export async function externalRequest(path, method, payload = null) {
  let [resp, status] = await _request(path, method, payload, true);
  return [resp, status];
}
