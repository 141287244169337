import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import cogoToast from "cogo-toast";

import ActionButton from "../components/ActionButton";
import LoadingButton from "../components/LoadingButton";
import useForm from "../hooks/useForm";
import { sendOfferToUs } from "../services/apiRouterService";
import Error from "./Error";
import { firstName, lastName, email, gdpr } from "../services/validationSchemas";
import TextInput from "./TextInput";

const SendOfferForm = ({
  calculatedPrices,
  materials,
  material,
  kitchenIsland,
  sizes,
  state,
  img,
  kitchenType,
  measured,
  delivered,
  installed,
}) => {
  const history = useHistory();
  const [isSending, setIsSending] = useState(false);
  const [, setShowSendOfferModal] = state;

  const SignupSchema = Yup.object().shape({
    first_name: firstName,
    last_name: lastName,
    email: email,
    gdpr: gdpr,
  });

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
  });

  const handleFormValues = async (formValues) => {
    setIsSending(true);
    const payload = {
      ...formValues,
      sizes,
      kitchenIsland,
      kitchenType,
      material,
      calculatedPrices,
      img,
      materials,
      measured: !!measured,
      delivered: !!delivered,
      installed: !!installed,
    };
    const [, status] = await sendOfferToUs(payload);
    setIsSending(false);
    if (status === 200) return history.push("/offerte-verzonden");
    cogoToast.error("Er liep iets mis, probeer later nog eens");
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleSubmit(handleFormValues);
  };

  return (
    <TotalContainer>
      <Title>Afronden</Title>
      <Description>
        Dit is de offerte van jouw ingegeven keukenbladen. Vul je persoonlijke
        informatie en en druk op verzenden.
      </Description>
      <OfferTable>
        {Object.keys(calculatedPrices?.surfacesPerWorksheet)?.map((key, i) => (
          <WorksheetRow key={i}>
            <WorksheetRowHead>
              <WorksheetName>
                {key === "kitchenIsland" ? "Keukeneiland" : `Werkblad ${i + 1}`}
                {" - "}
                {
                  materials[material?.categoryKey]?.items?.[
                    material?.materialKey
                  ]?.name
                }
                {" - "}
                {key === "kitchenIsland"
                  ? `${kitchenIsland?.width}mm - ${kitchenIsland?.length}mm`
                  : `${sizes?.[key]?.width}mm - ${sizes?.[key]?.length}mm`}
              </WorksheetName>
              <TotalPrice>
                {Number(
                  calculatedPrices?.surfacesPerWorksheet?.[key]
                ).toLocaleString("nl-BE", {
                  style: "currency",
                  currency: "EUR",
                })}
              </TotalPrice>
            </WorksheetRowHead>
            <ItemRow>
              <ItemRowName>Afgewerkte zijden{calculatedPrices?.edgesPerWorksheet?.[key]?.doubled && `(Verdubbeld)`}:</ItemRowName>
              <ItemRowPrice>
                {Number(
                  calculatedPrices?.edgesPerWorksheet?.[key]?.finished
                ).toLocaleString("nl-BE", {
                  style: "currency",
                  currency: "EUR",
                })}
              </ItemRowPrice>
            </ItemRow>
            {calculatedPrices?.sinksPerWorksheet?.[key]?.map((item, j) => (
              <ItemRow key={j}>
                <ItemRowName>Spoelbak: {item.name}:</ItemRowName>
                <ItemRowPrice>
                  {item?.discounted ? (
                    <>
                      <OldPrice>
                        {Number(item?.price).toLocaleString("nl-BE", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </OldPrice>
                      <NewPrice>
                        {Number(item?.actualPrice).toLocaleString("nl-BE", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </NewPrice>
                    </>
                  ) : (
                    <NewPrice>
                      {Number(item?.actualPrice).toLocaleString("nl-BE", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </NewPrice>
                  )}
                </ItemRowPrice>
              </ItemRow>
            ))}
            {calculatedPrices?.stovesPerWorksheet?.[key]?.map((item, j) => (
              <ItemRow key={j}>
                <ItemRowName>Uitsparing opbouw kookplaat:</ItemRowName>
                <ItemRowPrice>
                  {Number(item).toLocaleString("nl-BE", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </ItemRowPrice>
              </ItemRow>
            ))}
            {calculatedPrices?.elektricityPerWorksheet?.[key]?.map(
              (item, j) => (
                <ItemRow key={j}>
                  <ItemRowName>Uitsparing powerport:</ItemRowName>
                  <ItemRowPrice>
                    {Number(item).toLocaleString("nl-BE", {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </ItemRowPrice>
                </ItemRow>
              )
            )}
            <ItemRow>
              <ItemRowName>Subtotaal werkblad:</ItemRowName>
              <ItemRowPrice>
                {Number(
                  calculatedPrices?.totalWorksheetPrices?.[key]
                ).toLocaleString("nl-BE", {
                  style: "currency",
                  currency: "EUR",
                })}
              </ItemRowPrice>
            </ItemRow>
          </WorksheetRow>
        ))}
      </OfferTable>
      <Title>
        Totaal:{" "}
        {Number(calculatedPrices.total).toLocaleString("nl-BE", {
          style: "currency",
          currency: "EUR",
        }) || "-"}
      </Title>
      <FormContainer onSubmit={formSubmitHandler}>
        <StyledTextInput
          type="text"
          name="first_name"
          value={values.first_name}
          onBlur={() => handleSubmit(() => {})}
          onChange={handleChange}
          error={errors.first_name}
        >
          Voornaam*
        </StyledTextInput>
        <StyledTextInput
          type="text"
          name="last_name"
          value={values.last_name}
          onBlur={() => handleSubmit(() => {})}
          onChange={handleChange}
          error={errors.last_name}
        >
          Achternaam*
        </StyledTextInput>
        <StyledTextInput
          type="email"
          name="email"
          value={values.email}
          onBlur={() => handleSubmit(() => {})}
          onChange={handleChange}
          error={errors.email}
        >
          Email adres*
        </StyledTextInput>
        <div>
          <input type="checkbox" checked={!!values.gdpr} id="gdpr" name="gdpr" onChange={(e) => handleChange({ name: e.target.name, value: e.target.checked })} />
          <label htmlFor="gdpr" style={errors.gdpr ? ({color: 'red'}) : {}}>
            Ik heb de <a href="https://amantnv.be/privacy-policy" rel="noopener noreferrer" target="_blank">Privacy Policy</a> gelezen en ga hiermee akkoord.
          </label>
          <Error error={errors.gdpr} />
        </div>     
        <ButtonContainer>
          {isSending ? (
            <StyledLoadingButton>Versturen</StyledLoadingButton>
          ) : (
            <StyledActionButton disabled={isSending}>
              Verstuur offerte
            </StyledActionButton>
          )}
          <StyledActionButton onClick={() => setShowSendOfferModal(false)}>
            Annuleren
          </StyledActionButton>
        </ButtonContainer>
      </FormContainer>
    </TotalContainer>
  );
};

const StyledTextInput = styled(TextInput)``;

const FormContainer = styled.form`
  margin-top: 4rem;
`;

const Description = styled.p``;

const OfferTable = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;

const StyledLoadingButton = styled(LoadingButton)`
  margin: 0 1rem;
`;

const StyledActionButton = styled(ActionButton)`
  margin: 0 1rem;
`;

const TotalContainer = styled.div`
  width: 100%;
  background-color: white;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  padding: 2rem;
  border: 1px solid gray;
  padding-bottom: 4rem;
`;

const Title = styled.h2`
  font-weight: bold;
`;

const OldPrice = styled.span`
  text-decoration: line-through;
  color: #cdcdcd;
  margin-right: 1rem;
`;

const NewPrice = styled.span``;

const ItemRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  justify-content: space-between;
`;
const ItemRowName = styled.div``;
const ItemRowPrice = styled.div``;

const WorksheetName = styled.div`
  font-weight: bold;
`;

const TotalPrice = styled.div``;

const WorksheetRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  align-items: flex-end;
`;

const WorksheetRowHead = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  margin: 0 -1rem;
  display: flex;
  margin-top: 2rem;
`;

export default SendOfferForm;
