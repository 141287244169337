import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import useForm from "../hooks/useForm";

import { number } from "../services/validationSchemas";
import ActionButton from "./ActionButton";
import TextInput from "./TextInput";

const KitchenWorkSheetForm = ({ addAddendum, state, title }) => {
  const [, setShowSinkModal] = state || [];
  const SignupSchema = Yup.object().shape({
    width: number.required.min(1, "te klein").max(2000, "te groot"),
    height: number.required.min(1, "te klein").max(2000, "te groot"),
    x: number.required.min(1, "te klein").max(2000, "te groot"),
    y: number.required.min(1, "te klein").max(2000, "te groot"),
  });

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
  });

  const onSubmit = () => {
    const { x, y, width, height, sinkId } = values || {};
    const payload = {
      type: 'outlet',
      width,
      height,
      item: sinkId,
      x,
      y,
    }
    addAddendum(payload);
    setShowSinkModal(false);
  }

  return (
    <Container
      onSubmit={e => {
        e.preventDefault();
        handleSubmit(onSubmit);
      }}
    >
      <Title>{title}</Title>
      <Label>Uitsparing</Label>
      <TextInput
        type="number"
        name="width"
        value={values.width}
        onBlur={() => handleSubmit(() => {})}
        onChange={handleChange}
        error={errors.width}
      >
        Breedte
      </TextInput>
      <TextInput
        type="number"
        name="height"
        value={values.height}
        onBlur={() => handleSubmit(() => {})}
        onChange={handleChange}
        error={errors.height}
      >
        Lengte
      </TextInput>
      <Label>Locatie</Label>
      <TextInput
        type="number"
        name="x"
        value={values.x}
        onBlur={() => handleSubmit(() => {})}
        onChange={handleChange}
        error={errors.x}
      >
        X-positie
      </TextInput>
      <TextInput
        type="number"
        name="y"
        value={values.y}
        onBlur={() => handleSubmit(() => {})}
        onChange={handleChange}
        error={errors.y}
      >
        Y-positie
      </TextInput>
      <ButtonContainer>
        <StyledActionButton>
          Voeg toe
        </StyledActionButton>
        <StyledActionButton onClick={e => {
          e.preventDefault();
          setShowSinkModal(false);
        }}>
          Annuleer
        </StyledActionButton>
      </ButtonContainer>
    </Container>
  );
};

const Title = styled.h2`
  margin-bottom: 1.5rem;
`;

const Label = styled.h3`
  margin-bottom: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: -1rem;
`;

const StyledActionButton = styled(ActionButton)`
  margin: 1rem;
`;

const Container = styled.form``;

export default KitchenWorkSheetForm;
