import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { string, func, object, bool } from 'prop-types';

function borderColor(selected) {
  if (selected) {
    return '#366399';
  }
  return 'gray'
}

function iconColor(selected) {
  if (selected) {
    return '#366399';
  }
  return 'white';
}

function textColor(selected) {
  if (selected) {
    return 'white';
  }
  return 'gray';
}

const RadioButton = styled.label`
  font-family: ${({ theme }) => theme.font};
  font-size: 1.6rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 43px auto auto 27px;
  cursor: pointer;
  padding: 1rem;
  margin-right: 1.3vw;
  ${'' /* background-color: ${({ selected }) => backgroundColor(selected)}; */}

  border: 1px solid;
  border-color: ${({ selected }) => borderColor(selected)};
  box-sizing: border-box;
  border-radius: 5px;
  background-color: ${({ selected }) => iconColor(selected)};
  color: ${({ selected }) => textColor(selected)};
  transition: all .2s ease, transform 0.1s ease;

  &:active {
    transform: scale(0.9);
  }

  input {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
`;

RadioButton.displayName = 'RadioButton';

const SelectBox = forwardRef(
  ({ selected, label, value, onChange, name, ...otherProps }, ref) => {
    const handleChange = e => {
      e.persist();
      onChange({ name: name, value: e.target.checked });
    };

    return (
      <RadioButton selected={value} {...otherProps}>
        {label}
        <input
          ref={ref}
          type="checkbox"
          checked={value}
          onChange={handleChange}
          name={name}
        />
      </RadioButton>
    );
  },
);

SelectBox.displayName = 'SelectBox';

SelectBox.defaultProps = {
  label: 'means',
  onChange: () => {},
  name: 'radios',
  otherProps: {},
  selected: '',
  value: false,
};

SelectBox.propTypes = {
  selected: string,
  label: string,
  value: bool,
  onChange: func,
  mode: string,
  name: string,
  otherProps: object,
};

export default SelectBox;
